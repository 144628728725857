import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { Button, Heading, Checkbox, Link } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { useFormat } from 'helpers/hooks/useFormat';
import { Country } from 'helpers/utils/countryHelper';
import { useBusinessUnit } from 'frontastic';
import styles from './ctaWrapper.module.scss';
import { CheckoutFieldValidityType, CheckoutStep } from '../../index';

type CartSummaryProps = {
  activeStep: CheckoutStep;
  onCtaEvent: (activeStep: CheckoutStep) => void;
  setCheckoutFieldValidity: (updatedValidity: CheckoutFieldValidityType) => void;
};

const CtaWrapper: React.FC<CartSummaryProps> = ({ activeStep, onCtaEvent, setCheckoutFieldValidity }) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const [isChecked, setIsChecked] = useState(false);
  const [termsCondtionCheckedError, setTermsCondtionCheckedError] = useState(false);
  const { businessUnit } = useBusinessUnit();

  useEffect(() => {
    setCheckoutFieldValidity({ termsAndConditionsChecked: isChecked });
  }, [isChecked]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    setTermsCondtionCheckedError(false);
  };

  const getCtaText = (currentStep: CheckoutStep) => {
    switch (currentStep) {
      case CheckoutStep.SHIPPING:
        return formatMessage({
          id: 'select.payment.method',
          defaultMessage: 'Select payment method',
        });
      case CheckoutStep.PAYMENT:
        return formatMessage({
          id: 'review.order',
          defaultMessage: 'Review Order',
        });
      case CheckoutStep.REVIEW_SUMMARY:
        return formatMessage({
          id: 'place.order',
          defaultMessage: 'Place Order',
        });
    }
  };

  const additionalContent = () => {
    const country = businessUnit?.custom?.fields?.partner_country;

    const termsLink = (
      <NextLink href="/terms-of-sale" passHref>
        <Link variant="underline" target="_blank" textStyle="body-2" data-testid="cta-wrapper__tc-link_test-id">
          {formatMessage({ id: 'terms.of.sale', defaultMessage: 'Terms of Sale' })}
        </Link>
      </NextLink>
    );

    const privacyPolicyLink = (
      <NextLink href={country === Country.US ? '/privacy-policy-us' : '/privacy-policy-ca'} passHref>
        <Link variant="underline" target="_blank" textStyle="body-2" data-testid="cta-wrapper__tc-link_test-id">
          {formatMessage({ id: 'privacy.policy', defaultMessage: 'Privacy Policy' })}
        </Link>
      </NextLink>
    );

    return activeStep === CheckoutStep.REVIEW_SUMMARY ? (
      <div className={styles.additionalContentContainer}>
        <Checkbox
          required={false}
          id="checkbox"
          name="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          data-testid="cta-wrapper__enable-select_test-id"
        />
        <div className={styles.termsText}>
          <span>{formatMessage({ id: 'agree.to', defaultMessage: 'I agree to the ' })}</span>
          {termsLink}{' '}
          <span>
            {formatMessage({
              id: 'acknowledge',
              defaultMessage: 'and acknowledge that my personal information will be processed in accordance with the',
            })}
          </span>
          {privacyPolicyLink}
        </div>
        {termsCondtionCheckedError && (
          <p className={cs('lll-text-error', styles.termsAndConditionsError)}>
            {formatMessage({
              id: 'terms.and.conditions.error',
              defaultMessage: 'Please agree to the terms before placing the order.',
            })}
          </p>
        )}
      </div>
    ) : null;
  };

  const stepButtonCTA = (currentStep: CheckoutStep) => {
    const country = businessUnit?.custom?.fields?.partner_country;
    switch (currentStep) {
      case CheckoutStep.SHIPPING:
        return (
          <p data-testid="cta-wrapper__process-step-2-button-cta_test-id" className={styles.ctaButtonSteps}>
            {formatMessage({
              id: 'proceed.to.step.2',
              defaultMessage: 'Proceed to step 2 of 3',
            })}
          </p>
        );
      case CheckoutStep.PAYMENT:
        return (
          <p data-testid="cta-wrapper__process-step-3-button-cta_test-id" className={styles.ctaButtonSteps}>
            {formatMessage({
              id: 'proceed.to.step.3',
              defaultMessage: 'Proceed to step 3 of 3',
            })}
          </p>
        );
      case CheckoutStep.REVIEW_SUMMARY:
        return (
          <NextLink href={country === Country.US ? '/privacy-policy-us' : '/privacy-policy-ca'} passHref>
            <Link
              variant="underline"
              textStyle="body-3"
              className={styles.privacypolicy}
              data-testid="cta-wrapper__privacy-policy-link_test-id"
              target="_blank"
            >
              {formatMessage({ id: 'policy', defaultMessage: 'Read our security and privacy policy.' })}
            </Link>
          </NextLink>
        );
    }
  };

  return (
    <div data-testid="cta-wrapper__select-payment-method__component_test-id" className={styles.ctaWrapperContainer}>
      {additionalContent()}
      <Button
        id={`updateCart__button-${activeStep}`}
        kind="primary"
        className={styles.nextStepButton}
        onClick={() => {
          onCtaEvent(activeStep);
          const hasTnCError = activeStep === CheckoutStep.REVIEW_SUMMARY && !isChecked;
          setTermsCondtionCheckedError(hasTnCError);
        }}
        data-testid="cta-wrapper__update-cart__button_test-id"
      >
        {activeStep !== CheckoutStep.REVIEW_SUMMARY
          ? getCtaText(activeStep)
          : formatMessage({ id: 'place.order', defaultMessage: 'Place Order' })}
      </Button>
      {stepButtonCTA(activeStep)}
      {activeStep === CheckoutStep.SHIPPING && (
        <Heading
          wrapperClassName="w-full"
          className={cs(styles.orderReview)}
          tag="h2"
          data-testid="cta-wrapper__payment-method__heading_test-id"
        >
          {formatMessage({
            id: 'paymentMethod',
            defaultMessage: 'Payment method',
          })}
        </Heading>
      )}
      {activeStep !== CheckoutStep.REVIEW_SUMMARY && (
        <Heading
          wrapperClassName="w-full"
          className={cs(styles.orderReview)}
          tag="h2"
          data-testid="cta-wrapper__review-heading_test-id"
        >
          {formatMessage({
            id: 'review',
            defaultMessage: 'Review',
          })}
        </Heading>
      )}
    </div>
  );
};

export default CtaWrapper;
