import React, { FC, useEffect, useCallback, useMemo } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import cs from 'classnames';
import { NavItem } from 'shared/types/nav';
import { useNavContext } from 'context/nav';
import categoryQueryParams from 'helpers/categoryQueryParam';
import { HEADER } from 'helpers/utils/googleAnalyticsEvents';
import { createRefMap } from 'helpers/utils/nav-ref-map';
import { useAnalytics } from 'hooks/useAnalytics';
import { useProductList } from 'ui/cdp/context';
import { useAccount } from 'frontastic/index';
import styles from './primary-nav.module.scss';

export const PrimaryNavItem: FC<{ navItem: NavItem; innerRef: any; index: number }> = ({
  navItem,
  innerRef,
  index,
}) => {
  const router = useRouter();
  const { account } = useAccount();
  const { l1MainCategoryDisplayName = '', l1MainCategorySlug } = navItem;
  const { activeCategory, handleMouseEnter, navActivable, handleCloseDetailedNav } = useNavContext();
  const isActive = activeCategory?.l1MainCategorySlug === l1MainCategorySlug;
  const { limitStep } = useProductList();
  const categoryParams = categoryQueryParams(account, limitStep, null);
  //category link with all query params
  const categoryPath = `${navItem.path}?${categoryParams}`;
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();
  const onClickLink = () => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.PRIMARY_NAV_ITEM_CLICKED(navItem.name));
    navActivable.current = false;
    handleCloseDetailedNav();
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCloseDetailedNav();
      router.push(categoryPath || '');
      trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.PRIMARY_NAV_ITEM_CLICKED(navItem.name));
    }
  };

  const handleMouseLeave = () => {
    navActivable.current = true;
  };

  return (
    <div
      onFocus={() => handleMouseEnter(navItem, index, true)}
      onMouseEnter={() => handleMouseEnter(navItem, index)}
      onMouseLeave={handleMouseLeave}
      onKeyDown={onKeyDown}
      data-testid="primary-nav__item_test-id"
    >
      <NextLink href={categoryPath || ''}>
        <a
          className={cs(styles.underline, styles.anchor, styles.primaryNavAnchor, {
            [styles.primaryNavAnchorActive]: isActive,
          })}
          ref={innerRef}
          onClick={onClickLink}
        >
          {l1MainCategoryDisplayName}
        </a>
      </NextLink>
    </div>
  );
};

const PrimaryNav: FC<{ navData: NavItem[] }> = ({ navData }) => {
  const { getRef, setRef } = createRefMap();
  const {
    handleCloseDetailedNav,
    activeL1Index,
    setActiveL2Index,
    activeL2Index,
    setFeaturesActivated,
    setSubcategoriesActivated,
    menuLength,
    tabActivated,
    activeCategory,
    featuresActivated,
    subcategoriesActivated,
  } = useNavContext();
  const hasFeatures = !!activeCategory?.hasFeatures;

  const handleKeyDown = useCallback(
    (evt) => {
      switch (evt.key) {
        case 'ArrowDown': {
          evt.preventDefault();
          if (hasFeatures) {
            setFeaturesActivated(true);
          } else {
            setSubcategoriesActivated(true);
          }
          setActiveL2Index(0);
          break;
        }
        case 'Tab': {
          // NO PREVENT DEFAULT HERE SO THAT TABBING IS NOT BLOCKED FOR L1s
          if ((activeL1Index !== null && activeL1Index + 1 === menuLength) || (evt.shiftKey && activeL1Index === 0)) {
            handleCloseDetailedNav();
          }
          break;
        }
        default:
          break;
      }
    },
    [hasFeatures, activeL1Index],
  );

  useEffect(() => {
    if (tabActivated && !featuresActivated && !subcategoriesActivated) {
      const endOfMenu = activeL1Index === menuLength;
      if (endOfMenu) {
        handleCloseDetailedNav();
      } else {
        getRef(`l1-${activeL1Index}`)?.current.focus();
      }
    }
  }, [activeL1Index, tabActivated, activeL2Index, menuLength, featuresActivated, subcategoriesActivated]);

  return (
    <div className={cs(styles.sitePrimaryNav)} onKeyDown={handleKeyDown} data-testid="primary-nav__wrapper_test-id">
      <ul className={cs(styles.primaryNavList)}>
        {navData?.map((item, index) => (
          <li key={item?.categoryId}>
            <PrimaryNavItem navItem={item} index={index} innerRef={setRef(`l1-${index}`)} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PrimaryNav;
